import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './embedded.css'

const Embedded = (props) => {
  return (
    <div className="embedded-container">
      <Helmet>
        <title>Embedded - Weirdneutrino</title>
        <meta property="og:title" content="Embedded - Weirdneutrino" />
      </Helmet>
      <div className="embedded-container01">
        <div className="embedded-container02">
          <div className="embedded-container03">
            <Link to="/" className="embedded-navlink">
              <img
                alt="image"
                src="f95ea60c-825e-49c3-8c8d-23e9fabbcff1"
                className="embedded-image"
              />
            </Link>
            <div className="embedded-container04">
              <Link to="/" className="embedded-navlink01 menu">
                <span>Home</span>
              </Link>
              <Link to="/about" className="embedded-navlink02 menu">
                About
              </Link>
              <Link to="/solutions" className="embedded-navlink03 menu">
                Solutions
              </Link>
              <Link to="/contact" className="embedded-navlink04 menu">
                Contact
              </Link>
            </div>
          </div>
        </div>
        <div className="embedded-container05">
          <div className="embedded-container06">
            <span className="embedded-text01">
              <span>Embedded Services.</span>
            </span>
            <span className="embedded-text03">
              <span>
                We are an Embedded Design and Development Services expert
                consultancy; from automotive infotaiment/dashboard systems,
                medical devices, industrial automation, consumer electronics to
                silicon manufacturers we can proficiently help our clients
                overcome a wide range of technological challenges.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span></span>
            </span>
          </div>
        </div>
      </div>
      <div className="embedded-container07">
        <div className="embedded-container08">
          <div className="embedded-container09">
            <div className="embedded-container10">
              <span className="embedded-text06 sub-Heading">
                <span>Embedded Systems</span>
                <br></br>
                <span></span>
              </span>
              <span className="content">
                <span>
                  High-speed digital systems and high-resolution analog devices.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  We develop products featuring latest generation technology.
                </span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="embedded-container11">
              <img
                alt="image"
                src="/playground_assets/eb1-800w.png"
                className="embedded-image1"
              />
            </div>
          </div>
        </div>
        <div className="embedded-container12">
          <div className="embedded-container13">
            <div className="embedded-container14">
              <img
                alt="image"
                src="/playground_assets/eb2-800w.png"
                className="embedded-image2"
              />
            </div>
            <div className="embedded-container15">
              <span className="embedded-text13 sub-Heading">
                <span>
                  MCUs
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span></span>
              </span>
              <span className="content">
                <span>
                  Microcontrollers of any type of architecture, so that your
                  application includes the appropriate devices.
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>ARM® Cortex M0/M3/M4/M7/M33</span>
                <br></br>
                <span>RTOS customization</span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
        </div>
        <div className="embedded-container16">
          <div className="embedded-container17">
            <div className="embedded-container18">
              <span className="embedded-text22 sub-Heading">
                <span>Application Processors</span>
                <br></br>
                <span></span>
              </span>
              <span className="content">
                <span>
                  Latest generation processors enhance the intelligence and
                  performance of your product.
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>ARM® Cortex A7/A9/A35/A53/A72</span>
                <br></br>
                <span>Linux BSP customization and driver development</span>
                <br></br>
                <span>Android BSP customization and driver development</span>
                <br></br>
                <span>Application development</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="embedded-container19">
              <img
                alt="image"
                src="/playground_assets/eb3-800w.png"
                className="embedded-image3"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="embedded-container20">
        <div className="embedded-container21">
          <div className="embedded-container22">
            <div className="embedded-container23">
              <span className="embedded-text33 sub-Heading">
                <span>
                  Infrastructure
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  and System Architecture
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span></span>
              </span>
            </div>
            <span className="embedded-text38">
              <span>
                Infrastructure, communications and devices to design the best
                possible deployment according to your needs.
              </span>
              <br></br>
              <span></span>
            </span>
          </div>
          <div className="embedded-container24">
            <div className="embedded-container25">
              <span className="embedded-text41 sub-Heading">
                <span>Connected Devices</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <span className="embedded-text44">
              <span>
                Always-On products that utilize all digital radio protocols,
                from WiFi and Bluetooth to proprietary communications stacks for
                device networks.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span></span>
            </span>
          </div>
          <div className="embedded-container26">
            <div className="embedded-container27">
              <div className="embedded-container28">
                <span className="embedded-text47 sub-Heading">
                  <span>End-To-End Solutions</span>
                  <br></br>
                  <span></span>
                </span>
              </div>
              <span className="embedded-text50">
                <span>
                  We enrich our electronic design capabilities with modern
                  processing techniques that adjust to the demands of the
                  market.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="embedded-footer">
        <div className="embedded-container29">
          <div className="embedded-container30">
            <span className="embedded-text53 footer">We can help you</span>
            <Link to="/contact" className="embedded-navlink05 boton button">
              Send a message
            </Link>
          </div>
          <div className="embedded-container31">
            <Link to="/contact" className="embedded-navlink06 footer">
              Contact us
            </Link>
            <div className="embedded-container32">
              <div className="embedded-container33">
                <div className="embedded-container34">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="embedded-icon"
                  >
                    <path d="M199.429 357.143v566.286h-188.571v-566.286h188.571zM211.429 182.286c0.571 54.286-40.571 97.714-106.286 97.714v0h-1.143c-63.429 0-104-43.429-104-97.714 0-55.429 42.286-97.714 106.286-97.714 64.571 0 104.571 42.286 105.143 97.714zM877.714 598.857v324.571h-188v-302.857c0-76-27.429-128-95.429-128-52 0-82.857 34.857-96.571 68.571-4.571 12.571-6.286 29.143-6.286 46.286v316h-188c2.286-513.143 0-566.286 0-566.286h188v82.286h-1.143c24.571-38.857 69.143-95.429 170.857-95.429 124 0 216.571 81.143 216.571 254.857z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="embedded-icon2"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="embedded-icon4"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                  <svg viewBox="0 0 1024 1024" className="embedded-icon6">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>
                </div>
                <div className="embedded-container35">
                  <span className="boton">O. +52 (55) 8817 5337</span>
                  <span className="boton">info@wdno.io</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="embedded-container36">
          <div className="embedded-container37">
            <div className="embedded-container38">
              <div className="embedded-container39">
                <Link to="/" className="embedded-navlink07 boton">
                  Home
                </Link>
                <Link to="/about" className="embedded-navlink08 boton">
                  About
                </Link>
              </div>
              <div className="embedded-container40">
                <Link to="/solutions" className="embedded-navlink09 boton">
                  Solutions
                </Link>
                <Link to="/contact" className="embedded-navlink10 boton">
                  Contact
                </Link>
              </div>
            </div>
            <div className="embedded-container41">
              <span className="embedded-text56 boton">Weirdneutrino 2021</span>
              <Link to="/privacy" className="embedded-navlink11 boton">
                Privacy Notice
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Embedded
