import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './biometrics.css'

const Biometrics = (props) => {
  return (
    <div className="biometrics-container">
      <Helmet>
        <title>Biometrics - Weirdneutrino</title>
        <meta property="og:title" content="Biometrics - Weirdneutrino" />
      </Helmet>
      <div className="biometrics-container01">
        <div className="biometrics-container02">
          <div className="biometrics-container03">
            <Link to="/" className="biometrics-navlink">
              <img
                alt="image"
                src="f95ea60c-825e-49c3-8c8d-23e9fabbcff1"
                className="biometrics-image"
              />
            </Link>
            <div className="biometrics-container04">
              <Link to="/" className="biometrics-navlink01 menu">
                <span>Home</span>
              </Link>
              <Link to="/about" className="biometrics-navlink02 menu">
                About
              </Link>
              <Link to="/solutions" className="biometrics-navlink03 menu">
                Solutions
              </Link>
              <Link to="/contact" className="biometrics-navlink04 menu">
                Contact
              </Link>
            </div>
          </div>
        </div>
        <div className="biometrics-container05">
          <div className="biometrics-container06">
            <span className="biometrics-text01">
              <span>Biometrics.</span>
            </span>
            <span className="biometrics-text03">
              <span>
                State-of-the-art biometrics identity applications, by
                fingerprint, voice or facial recognition, seamlessly integrated
                with your process.
              </span>
              <br></br>
              <span></span>
            </span>
          </div>
        </div>
      </div>
      <div className="biometrics-container07">
        <div className="biometrics-container08">
          <span className="biometrics-text06 menu">
            <span>
              Weird Neutrino thoroughly processes biometric data to transform it
              into useful information.
            </span>
            <br></br>
            <span>​</span>
            <br></br>
            <span></span>
            <span>
              Weird Neutrino will help you identify a solution that fits and
              addresses your specific needs, such as business infrastructure and
              system vulnerabilities.
            </span>
            <br></br>
            <span></span>
            <span></span>
            <br></br>
            <span></span>
          </span>
          <span className="biometrics-text16 menu">
            <span>Integrated smart services for your identification</span>
            <br></br>
            <span></span>
            <br></br>
            <span>Highly trained and specialized engineering team</span>
            <br></br>
            <span></span>
            <br></br>
            <span>Lead time differentiation from the industry average</span>
            <br></br>
            <span></span>
          </span>
        </div>
      </div>
      <div className="biometrics-container09">
        <div className="biometrics-container10">
          <span className="biometrics-text23">
            <span>Smart Services</span>
            <br></br>
            <span></span>
          </span>
          <div className="biometrics-container11">
            <div className="biometrics-container12">
              <img
                alt="image"
                src="/playground_assets/b01-1200w.png"
                className="biometrics-image01"
              />
              <span className="biometrics-text26">
                <span>
                  Point-of-sale
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>applications</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="biometrics-container13">
              <img
                alt="image"
                src="/playground_assets/b02-1200w.png"
                className="biometrics-image02"
              />
              <span className="biometrics-text30">
                <span>
                  Address
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>compliancy</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="biometrics-container14">
              <img
                alt="image"
                src="/playground_assets/b03-1200w.png"
                className="biometrics-image03"
              />
              <span className="biometrics-text35">
                <span>
                  Proof of life
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>verification</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="biometrics-container15">
              <img
                alt="image"
                src="/playground_assets/b04-1200w.png"
                className="biometrics-image04"
              />
              <span className="biometrics-text40">
                <span>
                  Authenticate
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  users
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  reliably
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  and
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>conveniently</span>
              </span>
            </div>
            <div className="biometrics-container16">
              <img
                alt="image"
                src="/playground_assets/b05-1200w.png"
                className="biometrics-image05"
              />
              <span className="biometrics-text48">
                <span>
                  Fraud
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>prevention</span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="biometrics-container17">
        <div className="biometrics-container18">
          <span className="biometrics-text52">
            <span>Industries</span>
          </span>
          <div className="biometrics-container19">
            <div className="biometrics-container20">
              <img
                alt="image"
                src="/playground_assets/b06-1200w.png"
                className="biometrics-image06"
              />
              <span className="biometrics-text54">
                <span>
                  Corporate and
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  Public Security
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>System</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="biometrics-container21">
              <img
                alt="image"
                src="/playground_assets/b07-1200w.png"
                className="biometrics-image07"
              />
              <span className="biometrics-text61">
                <span>
                  Financial
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>Services</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="biometrics-container22">
              <img
                alt="image"
                src="/playground_assets/b08-1200w.png"
                className="biometrics-image08"
              />
              <span className="biometrics-text66">
                <span>
                  Law
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>Enforcement</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="biometrics-container23">
              <img
                alt="image"
                src="/playground_assets/b09-1200w.png"
                className="biometrics-image09"
              />
              <span className="biometrics-text71">
                <span>
                  Government
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>Affairs</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="biometrics-container24">
              <img
                alt="image"
                src="/playground_assets/b10-1200w.png"
                className="biometrics-image10"
              />
              <span className="biometrics-text76">
                <span>
                  Consumer
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>electronics</span>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="biometrics-footer">
        <div className="biometrics-container25">
          <div className="biometrics-container26">
            <span className="biometrics-text82 footer">We can help you</span>
            <Link to="/contact" className="biometrics-navlink05 boton button">
              Send a message
            </Link>
          </div>
          <div className="biometrics-container27">
            <Link to="/contact" className="biometrics-navlink06 footer">
              Contact us
            </Link>
            <div className="biometrics-container28">
              <div className="biometrics-container29">
                <div className="biometrics-container30">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="biometrics-icon"
                  >
                    <path d="M199.429 357.143v566.286h-188.571v-566.286h188.571zM211.429 182.286c0.571 54.286-40.571 97.714-106.286 97.714v0h-1.143c-63.429 0-104-43.429-104-97.714 0-55.429 42.286-97.714 106.286-97.714 64.571 0 104.571 42.286 105.143 97.714zM877.714 598.857v324.571h-188v-302.857c0-76-27.429-128-95.429-128-52 0-82.857 34.857-96.571 68.571-4.571 12.571-6.286 29.143-6.286 46.286v316h-188c2.286-513.143 0-566.286 0-566.286h188v82.286h-1.143c24.571-38.857 69.143-95.429 170.857-95.429 124 0 216.571 81.143 216.571 254.857z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="biometrics-icon2"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="biometrics-icon4"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                  <svg viewBox="0 0 1024 1024" className="biometrics-icon6">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>
                </div>
                <div className="biometrics-container31">
                  <span className="boton">O. +52 (55) 8817 5337</span>
                  <span className="boton">info@wdno.io</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="biometrics-container32">
          <div className="biometrics-container33">
            <div className="biometrics-container34">
              <div className="biometrics-container35">
                <Link to="/" className="biometrics-navlink07 boton">
                  Home
                </Link>
                <Link to="/about" className="biometrics-navlink08 boton">
                  About
                </Link>
              </div>
              <div className="biometrics-container36">
                <Link to="/solutions" className="biometrics-navlink09 boton">
                  Solutions
                </Link>
                <Link to="/contact" className="biometrics-navlink10 boton">
                  Contact
                </Link>
              </div>
            </div>
            <div className="biometrics-container37">
              <span className="biometrics-text85 boton">
                Weirdneutrino 2021
              </span>
              <Link to="/privacy" className="biometrics-navlink11 boton">
                Privacy Notice
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Biometrics
