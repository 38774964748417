import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import Contact from './views/contact'
import Privacy from './views/privacy'
import AI from './views/a-i'
import Solutions from './views/solutions'
import FullStack from './views/full-stack'
import Biometrics from './views/biometrics'
import Home from './views/home'
import About from './views/about'
import Embedded from './views/embedded'

const App = () => {
  return (
    <Router>
      <div>
        <Route exact component={Contact} path="/contact" />
        <Route exact component={Privacy} path="/privacy" />
        <Route exact component={AI} path="/a-i" />
        <Route exact component={Solutions} path="/solutions" />
        <Route exact component={FullStack} path="/full-stack" />
        <Route exact component={Biometrics} path="/biometrics" />
        <Route exact component={Home} path="/" />
        <Route exact component={About} path="/about" />
        <Route exact component={Embedded} path="/embedded" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
