import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './solutions.css'

const Solutions = (props) => {
  return (
    <div className="solutions-container">
      <Helmet>
        <title>Solutions - Weirdneutrino</title>
        <meta property="og:title" content="Solutions - Weirdneutrino" />
      </Helmet>
      <div className="solutions-container01">
        <div className="solutions-container02">
          <div className="solutions-container03">
            <Link to="/" className="solutions-navlink">
              <img
                alt="image"
                src="/playground_assets/6asset%202-200h.png"
                className="solutions-image"
              />
            </Link>
            <div className="solutions-container04">
              <Link to="/" className="solutions-navlink01 menu">
                <span>Home</span>
              </Link>
              <Link to="/about" className="solutions-navlink02 menu">
                About
              </Link>
              <Link to="/solutions" className="solutions-navlink03 menu">
                Solutions
              </Link>
              <Link to="/contact" className="solutions-navlink04 menu">
                Contact
              </Link>
            </div>
          </div>
        </div>
        <div className="solutions-container05">
          <div className="solutions-container06">
            <span className="solutions-text01">
              <span>Solutions.</span>
            </span>
            <span className="solutions-text03">
              <span>
                We develop technology that adapts to your operations using the
                best techniques, tools, devices and components on the market.
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                We create scalable, expandable and flexible solutions that are
                open to continuous development processes.
              </span>
              <br></br>
              <span></span>
            </span>
          </div>
        </div>
      </div>
      <div className="solutions-container07">
        <div className="solutions-container08">
          <div className="solutions-container09">
            <div className="solutions-container10">
              <Link to="/full-stack" className="solutions-navlink05">
                <img
                  alt="image"
                  src="/playground_assets/6asset%20101-200h.png"
                  className="solutions-image1"
                />
              </Link>
              <Link to="/full-stack" className="solutions-navlink06 heading1">
                Full Stack Software
              </Link>
            </div>
            <span className="solutions-text08 menu">
              <span>
                Highly experienced multidisciplinary team, technically trained
                to develop solutions in the spectrum of back-end to front-end.
              </span>
              <br></br>
              <span></span>
            </span>
            <Link to="/full-stack" className="solutions-navlink07 button">
              Learn more
            </Link>
          </div>
          <div className="solutions-container11">
            <div className="solutions-container12">
              <Link to="/biometrics" className="solutions-navlink08">
                <img
                  alt="image"
                  src="/playground_assets/6asset%2092-200h.png"
                  className="solutions-image2"
                />
              </Link>
              <Link to="/biometrics" className="solutions-navlink09 heading1">
                Biometrics
              </Link>
            </div>
            <span className="solutions-text11 menu">
              <span>
                Weird Neutrino thoroughly processes biometric data to transform
                it into useful information, we can
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                help you identify a solution that fits and addresses your
                specific needs, such as business infrastructure and system
                vulnerabilities.
              </span>
              <br></br>
              <span></span>
              <span></span>
              <br></br>
              <span></span>
            </span>
            <Link to="/biometrics" className="solutions-navlink10 button">
              Learn more
            </Link>
          </div>
          <div className="solutions-container13">
            <div className="solutions-container14">
              <Link to="/a-i" className="solutions-navlink11">
                <img
                  alt="image"
                  src="/playground_assets/6asset%20111-200h.png"
                  className="solutions-image3"
                />
              </Link>
              <Link to="/a-i" className="solutions-navlink12 heading1">
                Ai &amp; Machine Learning
              </Link>
            </div>
            <span className="solutions-text17 menu">
              <span>
                We have great expertise to apply ML &amp; AI techniques to
                enable your business with state-of-the-art technologies that
                provide actionable information in real time.
              </span>
              <br></br>
              <span></span>
            </span>
            <Link to="/a-i" className="solutions-navlink13 button">
              Learn more
            </Link>
          </div>
          <div className="solutions-container15">
            <div className="solutions-container16">
              <Link to="/embedded" className="solutions-navlink14">
                <img
                  alt="image"
                  src="/playground_assets/6asset%20121-200h.png"
                  className="solutions-image4"
                />
              </Link>
              <Link to="/embedded" className="solutions-navlink15 heading1">
                Embedded Services
              </Link>
            </div>
            <span className="solutions-text20 menu">
              <span>
                We are an Embedded Design and Development Services expert
                consultancy; from automotive infotaiment/dashboard systems,
                medical devices, industrial automation, consumer electronics to
                silicon manufacturers we can proficiently help our clients
                overcome a wide range of technological challenges.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span></span>
            </span>
            <Link to="/embedded" className="solutions-navlink16 button">
              Learn more
            </Link>
          </div>
        </div>
      </div>
      <div className="solutions-footer">
        <div className="solutions-container17">
          <div className="solutions-container18">
            <span className="solutions-text23 footer">We can help you</span>
            <Link to="/contact" className="solutions-navlink17 boton button">
              Send a message
            </Link>
          </div>
          <div className="solutions-container19">
            <Link to="/contact" className="solutions-navlink18 footer">
              Contact us
            </Link>
            <div className="solutions-container20">
              <div className="solutions-container21">
                <div className="solutions-container22">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="solutions-icon"
                  >
                    <path d="M199.429 357.143v566.286h-188.571v-566.286h188.571zM211.429 182.286c0.571 54.286-40.571 97.714-106.286 97.714v0h-1.143c-63.429 0-104-43.429-104-97.714 0-55.429 42.286-97.714 106.286-97.714 64.571 0 104.571 42.286 105.143 97.714zM877.714 598.857v324.571h-188v-302.857c0-76-27.429-128-95.429-128-52 0-82.857 34.857-96.571 68.571-4.571 12.571-6.286 29.143-6.286 46.286v316h-188c2.286-513.143 0-566.286 0-566.286h188v82.286h-1.143c24.571-38.857 69.143-95.429 170.857-95.429 124 0 216.571 81.143 216.571 254.857z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="solutions-icon2"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="solutions-icon4"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                  <svg viewBox="0 0 1024 1024" className="solutions-icon6">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>
                </div>
                <div className="solutions-container23">
                  <span className="boton">O. +52 (55) 8817 5337</span>
                  <span className="boton">info@wdno.io</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="solutions-container24">
          <div className="solutions-container25">
            <div className="solutions-container26">
              <div className="solutions-container27">
                <Link to="/" className="solutions-navlink19 boton">
                  Home
                </Link>
                <Link to="/about" className="solutions-navlink20 boton">
                  About
                </Link>
              </div>
              <div className="solutions-container28">
                <Link to="/solutions" className="solutions-navlink21 boton">
                  Solutions
                </Link>
                <Link to="/contact" className="solutions-navlink22 boton">
                  Contact
                </Link>
              </div>
            </div>
            <div className="solutions-container29">
              <span className="solutions-text26 boton">Weirdneutrino 2021</span>
              <Link to="/privacy" className="solutions-navlink23 boton">
                Privacy Notice
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Solutions
