import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './full-stack.css'

const FullStack = (props) => {
  return (
    <div className="full-stack-container">
      <Helmet>
        <title>FullStack - Weirdneutrino</title>
        <meta property="og:title" content="FullStack - Weirdneutrino" />
      </Helmet>
      <div className="full-stack-container01">
        <div className="full-stack-container02">
          <div className="full-stack-container03">
            <Link to="/" className="full-stack-navlink">
              <img
                alt="image"
                src="f95ea60c-825e-49c3-8c8d-23e9fabbcff1"
                className="full-stack-image"
              />
            </Link>
            <div className="full-stack-container04">
              <Link to="/" className="full-stack-navlink01 menu">
                <span>Home</span>
              </Link>
              <Link to="/about" className="full-stack-navlink02 menu">
                About
              </Link>
              <Link to="/solutions" className="full-stack-navlink03 menu">
                Solutions
              </Link>
              <Link to="/contact" className="full-stack-navlink04 menu">
                Contact
              </Link>
            </div>
          </div>
        </div>
        <div className="full-stack-container05">
          <div className="full-stack-container06">
            <span className="full-stack-text01">
              <span>Full Stack Software.</span>
            </span>
            <span className="full-stack-text03">
              <span>
                Highly experienced multidisciplinary team, technically trained
                to develop solutions in the spectrum of back-end to front-end.
              </span>
              <br></br>
              <span></span>
            </span>
          </div>
        </div>
      </div>
      <div className="full-stack-container07">
        <div className="full-stack-container08">
          <div className="full-stack-container09">
            <img
              alt="image"
              src="/playground_assets/fs03-400w.png"
              className="full-stack-image01"
            />
            <span className="full-stack-text06 sub-Heading">
              <span>Technological Flexibility</span>
              <br></br>
              <span></span>
            </span>
            <span className="full-stack-text09">
              <span>
                Our solutions allow our customers to choose to work with open
                technology ecosystems or proprietary technology ecosystems.
              </span>
              <br></br>
              <span></span>
            </span>
          </div>
          <div className="full-stack-container10">
            <img
              alt="image"
              src="/playground_assets/fs04-500w.png"
              className="full-stack-image02"
            />
            <span className="full-stack-text12 sub-Heading">
              <span>Wide Spectrum Solutions</span>
              <br></br>
              <span></span>
            </span>
            <span className="full-stack-text15">
              <span>(Embedded &amp; Full Stack Software)</span>
              <br></br>
              <span></span>
            </span>
            <span className="full-stack-text18">
              <span>
                Our offer is unique in the local market, we integrate
                technologies in such a wide range that allows us to develop
                solutions that combine embedded systems and Full Stack Software.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span></span>
            </span>
          </div>
          <div className="full-stack-container11">
            <img
              alt="image"
              src="/playground_assets/fs02-400w.png"
              className="full-stack-image03"
            />
            <div className="full-stack-container12">
              <span className="full-stack-text21 sub-Heading">
                <span>Scalability</span>
                <br></br>
                <span></span>
              </span>
              <span className="full-stack-text24">
                <span>
                  We develop scalable projects, designed to continue adapting
                  within time, so our client&apos;s initial investment does not
                  depreciate in the short term.
                </span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="full-stack-container13">
        <div className="full-stack-container14">
          <span className="full-stack-text27">
            <span>
              We develop smart solutions, from the visual experience of the
              users or consumers of our customers, all the way to the back-end
              in the cloud.
            </span>
            <br></br>
            <span></span>
          </span>
          <img
            alt="image"
            src="/playground_assets/6asset%201-1200w.png"
            className="full-stack-image04"
          />
        </div>
      </div>
      <div className="full-stack-container15">
        <div className="full-stack-container16">
          <div className="full-stack-container17">
            <div className="full-stack-container18">
              <span className="full-stack-text30">
                <span>Reliability</span>
                <br></br>
                <span></span>
              </span>
              <img
                alt="image"
                src="/playground_assets/fs05-1200w.png"
                className="full-stack-image05"
              />
            </div>
            <div className="full-stack-container19">
              <span className="full-stack-text33">
                <span>Profitability</span>
                <br></br>
                <span></span>
              </span>
              <img
                alt="image"
                src="/playground_assets/fs06-1200w.png"
                className="full-stack-image06"
              />
            </div>
            <div className="full-stack-container20">
              <span className="full-stack-text36">
                <span>Agility</span>
                <br></br>
                <span></span>
              </span>
              <img
                alt="image"
                src="/playground_assets/fs07-1200w.png"
                className="full-stack-image07"
              />
            </div>
            <div className="full-stack-container21">
              <span className="full-stack-text39">
                <span>Versatility</span>
                <br></br>
                <span></span>
              </span>
              <img
                alt="image"
                src="/playground_assets/fs08-1200w.png"
                className="full-stack-image08"
              />
            </div>
            <div className="full-stack-container22">
              <span className="full-stack-text42">
                <span>Security</span>
                <br></br>
                <span></span>
              </span>
              <img
                alt="image"
                src="/playground_assets/fs09-1200w.png"
                className="full-stack-image09"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="full-stack-container23">
        <div className="full-stack-container24">
          <span className="full-stack-text45">Benefits</span>
          <div className="full-stack-container25">
            <div className="full-stack-container26">
              <img
                alt="image"
                src="/playground_assets/fs15-200h.png"
                className="full-stack-image10"
              />
              <span className="full-stack-text46">
                <span>
                  We develop high quality functional products, below the
                  industry’s lead time.
                </span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="full-stack-container27">
              <img
                alt="image"
                src="/playground_assets/asset%201-200h.png"
                className="full-stack-image11"
              />
              <span className="full-stack-text49">
                <span>Technologically groundbreaking</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="full-stack-container28">
              <img
                alt="image"
                src="/playground_assets/asset%202-200w.png"
                className="full-stack-image12"
              />
              <span className="full-stack-text52">
                <span>Scalability throughout all our products.</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="full-stack-container29">
              <img
                alt="image"
                src="/playground_assets/fs22-01-200h.png"
                className="full-stack-image13"
              />
              <span className="full-stack-text55">
                <span>Technology compliance</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="full-stack-container30">
              <img
                alt="image"
                src="/playground_assets/fs13-200h.png"
                className="full-stack-image14"
              />
              <span className="full-stack-text58">
                <span>
                  We go side by side with our clients throughout the process.
                </span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="full-stack-container31">
        <div className="full-stack-container32">
          <span className="full-stack-text61">Industries</span>
          <div className="full-stack-container33">
            <div className="full-stack-container34">
              <img
                alt="image"
                src="/playground_assets/fs16-1200w.png"
                className="full-stack-image15"
              />
              <span className="full-stack-text62">
                <span>Corporate</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="full-stack-container35">
              <img
                alt="image"
                src="/playground_assets/fs17-1200w.png"
                className="full-stack-image16"
              />
              <span className="full-stack-text65">
                <span>
                  Government
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>Affairs</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="full-stack-container36">
              <img
                alt="image"
                src="/playground_assets/fs18-1200w.png"
                className="full-stack-image17"
              />
              <span className="full-stack-text70">
                <span>Pharmaceutics</span>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="full-stack-container37">
              <img
                alt="image"
                src="/playground_assets/fs19-1200w.png"
                className="full-stack-image18"
              />
              <span className="full-stack-text74">
                <span>Healthcare</span>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="full-stack-container38">
              <img
                alt="image"
                src="/playground_assets/fs20-1200w.png"
                className="full-stack-image19"
              />
              <span className="full-stack-text78">
                <span>Banking</span>
                <br></br>
                <span></span>
              </span>
            </div>
            <div className="full-stack-container39">
              <img
                alt="image"
                src="/playground_assets/fs21-1200w.png"
                className="full-stack-image20"
              />
              <span className="full-stack-text81">
                <span>Energy</span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="full-stack-footer">
        <div className="full-stack-container40">
          <div className="full-stack-container41">
            <span className="full-stack-text84 footer">We can help you</span>
            <Link to="/contact" className="full-stack-navlink05 boton button">
              Send a message
            </Link>
          </div>
          <div className="full-stack-container42">
            <Link to="/contact" className="full-stack-navlink06 footer">
              Contact us
            </Link>
            <div className="full-stack-container43">
              <div className="full-stack-container44">
                <div className="full-stack-container45">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="full-stack-icon"
                  >
                    <path d="M199.429 357.143v566.286h-188.571v-566.286h188.571zM211.429 182.286c0.571 54.286-40.571 97.714-106.286 97.714v0h-1.143c-63.429 0-104-43.429-104-97.714 0-55.429 42.286-97.714 106.286-97.714 64.571 0 104.571 42.286 105.143 97.714zM877.714 598.857v324.571h-188v-302.857c0-76-27.429-128-95.429-128-52 0-82.857 34.857-96.571 68.571-4.571 12.571-6.286 29.143-6.286 46.286v316h-188c2.286-513.143 0-566.286 0-566.286h188v82.286h-1.143c24.571-38.857 69.143-95.429 170.857-95.429 124 0 216.571 81.143 216.571 254.857z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="full-stack-icon2"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="full-stack-icon4"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                  <svg viewBox="0 0 1024 1024" className="full-stack-icon6">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>
                </div>
                <div className="full-stack-container46">
                  <span className="boton">O. +52 (55) 8817 5337</span>
                  <span className="boton">info@wdno.io</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="full-stack-container47">
          <div className="full-stack-container48">
            <div className="full-stack-container49">
              <div className="full-stack-container50">
                <Link to="/" className="full-stack-navlink07 boton">
                  Home
                </Link>
                <Link to="/about" className="full-stack-navlink08 boton">
                  About
                </Link>
              </div>
              <div className="full-stack-container51">
                <Link to="/solutions" className="full-stack-navlink09 boton">
                  Solutions
                </Link>
                <Link to="/contact" className="full-stack-navlink10 boton">
                  Contact
                </Link>
              </div>
            </div>
            <div className="full-stack-container52">
              <span className="full-stack-text87 boton">
                Weirdneutrino 2021
              </span>
              <Link to="/privacy" className="full-stack-navlink11 boton">
                Privacy Notice
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullStack
