import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './contact.css'

const Contact = (props) => {
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - Weirdneutrino</title>
        <meta property="og:title" content="Contact - Weirdneutrino" />
      </Helmet>
      <div className="contact-container01">
        <div className="contact-container02">
          <div className="contact-container03">
            <Link to="/" className="contact-navlink">
              <img
                alt="image"
                src="f95ea60c-825e-49c3-8c8d-23e9fabbcff1"
                className="contact-image"
              />
            </Link>
            <div className="contact-container04">
              <Link to="/" className="contact-navlink01 menu">
                <span>Home</span>
              </Link>
              <Link to="/about" className="contact-navlink02 menu">
                About
              </Link>
              <Link to="/solutions" className="contact-navlink03 menu">
                Solutions
              </Link>
              <Link to="/contact" className="contact-navlink04 menu">
                Contact
              </Link>
            </div>
          </div>
        </div>
        <div className="contact-container05">
          <div className="contact-container06">
            <span className="contact-text01">
              <span>Contact.</span>
            </span>
          </div>
        </div>
      </div>
      <div className="contact-container07">
        <div className="contact-container08">
          <span className="contact-text03">
            <span>Let’s connect</span>
            <br></br>
            <span></span>
          </span>
          <span className="contact-text06 menu">
            Send us a message and we’ll get in touch right away.
          </span>
          <form className="contact-form">
            <label className="contact-text07 sub-Heading">Name</label>
            <input
              type="text"
              placeholder="Full Name"
              className="contact-textinput input"
            />
            <label className="contact-text08 sub-Heading">Email</label>
            <input
              type="text"
              placeholder="Email"
              className="contact-textinput1 input"
            />
            <label className="contact-text09 sub-Heading">
              <span>Message</span>
              <br></br>
              <span></span>
            </label>
            <textarea
              placeholder="Write something"
              className="contact-textarea textarea"
            ></textarea>
            <button className="contact-button boton button">Send</button>
          </form>
        </div>
      </div>
      <div className="contact-footer">
        <div className="contact-container09">
          <div className="contact-container10">
            <span className="contact-text12 footer">We can help you</span>
            <Link to="/contact" className="contact-navlink05 button boton">
              Send a message
            </Link>
          </div>
          <div className="contact-container11">
            <Link to="/contact" className="contact-navlink06 footer">
              Contact us
            </Link>
            <div className="contact-container12">
              <div className="contact-container13">
                <div className="contact-container14">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="contact-icon"
                  >
                    <path d="M199.429 357.143v566.286h-188.571v-566.286h188.571zM211.429 182.286c0.571 54.286-40.571 97.714-106.286 97.714v0h-1.143c-63.429 0-104-43.429-104-97.714 0-55.429 42.286-97.714 106.286-97.714 64.571 0 104.571 42.286 105.143 97.714zM877.714 598.857v324.571h-188v-302.857c0-76-27.429-128-95.429-128-52 0-82.857 34.857-96.571 68.571-4.571 12.571-6.286 29.143-6.286 46.286v316h-188c2.286-513.143 0-566.286 0-566.286h188v82.286h-1.143c24.571-38.857 69.143-95.429 170.857-95.429 124 0 216.571 81.143 216.571 254.857z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="contact-icon2"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="contact-icon4"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                  <svg viewBox="0 0 1024 1024" className="contact-icon6">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>
                </div>
                <div className="contact-container15">
                  <span className="boton">O. +52 (55) 8817 5337</span>
                  <span className="boton">info@wdno.io</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-container16">
          <div className="contact-container17">
            <div className="contact-container18">
              <div className="contact-container19">
                <Link to="/" className="contact-navlink07 boton">
                  Home
                </Link>
                <Link to="/about" className="contact-navlink08 boton">
                  About
                </Link>
              </div>
              <div className="contact-container20">
                <Link to="/solutions" className="contact-navlink09 boton">
                  Solutions
                </Link>
                <Link to="/contact" className="contact-navlink10 boton">
                  Contact
                </Link>
              </div>
            </div>
            <div className="contact-container21">
              <span className="contact-text15 boton">Weirdneutrino 2021</span>
              <Link to="/privacy" className="contact-navlink11 boton">
                Privacy Notice
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
