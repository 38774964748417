import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './a-i.css'

const AI = (props) => {
  return (
    <div className="a-i-container">
      <Helmet>
        <title>AI - Weirdneutrino</title>
        <meta property="og:title" content="AI - Weirdneutrino" />
      </Helmet>
      <div className="a-i-container01">
        <div className="a-i-container02">
          <div className="a-i-container03">
            <Link to="/" className="a-i-navlink">
              <img
                alt="image"
                src="f95ea60c-825e-49c3-8c8d-23e9fabbcff1"
                className="a-i-image"
              />
            </Link>
            <div className="a-i-container04">
              <Link to="/" className="a-i-navlink1 menu">
                <span>Home</span>
              </Link>
              <Link to="/about" className="a-i-navlink2 menu">
                About
              </Link>
              <Link to="/solutions" className="a-i-navlink3 menu">
                Solutions
              </Link>
              <span className="a-i-text01 menu">Contact</span>
            </div>
          </div>
        </div>
        <div className="a-i-container05">
          <div className="a-i-container06">
            <span className="a-i-text02">
              <span>Ai &amp; Machine Learning</span>
            </span>
            <span className="a-i-text04">
              <span>
                We have great expertise to apply ML &amp; AI techniques to
                enable your business with state-of-the-art technologies that
                provide actionable information in real time.
              </span>
              <br></br>
              <span></span>
            </span>
          </div>
        </div>
      </div>
      <div className="a-i-container07">
        <div className="a-i-container08">
          <div className="a-i-container09">
            <div className="a-i-container10">
              <img
                alt="image"
                src="/playground_assets/cv1-400w.png"
                className="a-i-image01"
              />
            </div>
            <div className="a-i-container11">
              <span className="a-i-text07 sub-Heading">Computer Vision</span>
              <span className="a-i-text08">
                <span>
                  - Classification: Perform classification of documents and
                  images.
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  - Detection: Locate where a person, face or any object is in
                  an image and draw a bounding box around it.
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  - Pose estimation: Use computer vision to detect the position
                  of a person or object.
                </span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
          <div className="a-i-container12">
            <div className="a-i-container13">
              <img
                alt="image"
                src="/playground_assets/cv2-400w.png"
                className="a-i-image02"
              />
            </div>
            <div className="a-i-container14">
              <span className="a-i-text15 sub-Heading">Time Series</span>
              <span className="a-i-text16">
                <span>
                  - Look at historical data to predict future behavior.
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  - Perform speech recognition and natural language processing.
                </span>
              </span>
            </div>
          </div>
          <div className="a-i-container15">
            <div className="a-i-container16">
              <img
                alt="image"
                src="/playground_assets/cv3-400w.png"
                className="a-i-image03"
              />
            </div>
            <div className="a-i-container17">
              <span className="a-i-text22 sub-Heading">
                <span>
                  Reinforcement Learning
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span></span>
              </span>
              <span className="a-i-text25">
                <span>
                  Let the machine learn by letting it look at how you perform an
                  activity and then letting it try by itself.
                </span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="a-i-container18">
        <div className="a-i-container19">
          <div className="a-i-container20">
            <div className="a-i-container21">
              <img
                alt="image"
                src="/playground_assets/ai1-400w.png"
                className="a-i-image04"
              />
            </div>
            <div className="a-i-container22">
              <span className="a-i-text28 sub-Heading">
                <span>Recommender systems</span>
                <br></br>
                <span></span>
              </span>
              <span className="a-i-text31">
                <span>
                  Find the hidden relationships that exists between users-users,
                  products-products and users-products.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  Recommend products, documents and articles that maximize
                  probability of success for your users.
                </span>
              </span>
            </div>
          </div>
          <div className="a-i-container23">
            <div className="a-i-container24">
              <img
                alt="image"
                src="/playground_assets/aiasset%201-500w.png"
                className="a-i-image05"
              />
            </div>
            <div className="a-i-container25">
              <span className="a-i-text37 sub-Heading">
                Natural Language Processing
              </span>
              <span className="a-i-text38">
                <span>
                  Identify the intent of your users and provide them with
                  automatic assistance.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </div>
          </div>
          <div className="a-i-container26">
            <div className="a-i-container27">
              <img
                alt="image"
                src="/playground_assets/ai3-400w.png"
                className="a-i-image06"
              />
            </div>
            <div className="a-i-container28">
              <span className="a-i-text40 sub-Heading">
                Document Classification
              </span>
              <span className="a-i-text41">
                <span>
                  Classify and extract information from scanned documents
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="a-i-container29">
        <div className="a-i-container30">
          <div className="a-i-container31">
            <div className="a-i-container32">
              <img
                alt="image"
                src="/playground_assets/ai2-400w.png"
                className="a-i-image07"
              />
            </div>
            <div className="a-i-container33">
              <span className="a-i-text43 sub-Heading">
                <span>Security</span>
              </span>
              <span className="a-i-text45">
                <span>
                  Identify behaviors, known subjects and forbidden objects by
                  processing surveillance video feed in real time.
                </span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
          <div className="a-i-container34">
            <div className="a-i-container35">
              <img
                alt="image"
                src="/playground_assets/ai6-400w.png"
                className="a-i-image08"
              />
            </div>
            <div className="a-i-container36">
              <span className="a-i-text48 sub-Heading">
                <span>Retail</span>
                <br></br>
                <span></span>
              </span>
              <span className="a-i-text51">
                <span>
                  Obtain information about your customers segmented by
                  demographics and emotional reactions.
                </span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
          <div className="a-i-container37">
            <div className="a-i-container38">
              <img
                alt="image"
                src="/playground_assets/aiiasset%201-400w.png"
                className="a-i-image09"
              />
            </div>
            <div className="a-i-container39">
              <span className="a-i-text54 sub-Heading">Biometrics</span>
              <span className="a-i-text55">
                <span>
                  Apply state of the art techniques for facial, voice and
                  fingerprint recognition.
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="a-i-footer">
        <div className="a-i-container40">
          <div className="a-i-container41">
            <span className="a-i-text57 footer">We can help you</span>
            <button className="a-i-button boton button">Send a message</button>
          </div>
          <div className="a-i-container42">
            <span className="a-i-text58 footer">Contact us</span>
            <div className="a-i-container43">
              <div className="a-i-container44">
                <div className="a-i-container45">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="a-i-icon"
                  >
                    <path d="M199.429 357.143v566.286h-188.571v-566.286h188.571zM211.429 182.286c0.571 54.286-40.571 97.714-106.286 97.714v0h-1.143c-63.429 0-104-43.429-104-97.714 0-55.429 42.286-97.714 106.286-97.714 64.571 0 104.571 42.286 105.143 97.714zM877.714 598.857v324.571h-188v-302.857c0-76-27.429-128-95.429-128-52 0-82.857 34.857-96.571 68.571-4.571 12.571-6.286 29.143-6.286 46.286v316h-188c2.286-513.143 0-566.286 0-566.286h188v82.286h-1.143c24.571-38.857 69.143-95.429 170.857-95.429 124 0 216.571 81.143 216.571 254.857z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="a-i-icon2"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="a-i-icon4"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                  <svg viewBox="0 0 1024 1024" className="a-i-icon6">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>
                </div>
                <div className="a-i-container46">
                  <span className="boton">O. +52 (55) 8817 5337</span>
                  <span className="boton">info@wdno.io</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="a-i-container47">
          <div className="a-i-container48">
            <div className="a-i-container49">
              <div className="a-i-container50">
                <Link to="/" className="a-i-navlink4 boton">
                  Home
                </Link>
                <Link to="/about" className="a-i-navlink5 boton">
                  About
                </Link>
              </div>
              <div className="a-i-container51">
                <Link to="/solutions" className="a-i-navlink6 boton">
                  Solutions
                </Link>
                <Link to="/contact" className="a-i-navlink7 boton">
                  Contact
                </Link>
              </div>
            </div>
            <div className="a-i-container52">
              <span className="a-i-text61 boton">Weirdneutrino 2021</span>
              <Link to="/privacy" className="a-i-navlink8 boton">
                Privacy Notice
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AI
